import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { HeroH1, HeroP } from '../../styles/Utility.styles'

export const LandingPageHero = styled.div`
  display: grid;
  grid-template-columns: 32px repeat(7, 1fr) 0.5fr 0.5fr repeat(2, 1fr) 32px;
  grid-template-rows: 51px max-content ${({ halfHeight }) =>
      halfHeight ? '26px' : '52px'} auto;
  padding: 31px 0;

  @media ${DEVICE.mobileL} {
    padding-top: 56px;
    grid-template-columns: 1fr repeat(6, 1fr) 0.75fr 0.25fr repeat(4, 1fr);
    grid-template-rows: 51px max-content ${({ halfHeight }) =>
        halfHeight ? '16.5px' : '33px'} auto;
    max-width: 1317px;
  }

  @media ${DEVICE.tabletG} {
    grid-template-columns: 1fr repeat(8, 1fr) 0.75fr 0.25fr 1fr 1fr;
    grid-template-rows: 93px max-content ${({ halfHeight }) =>
        halfHeight ? '64px' : '128px'};
  }
`

export const TextRegion = styled.div`
  grid-column: 2 / 13;
  grid-row: 2;

  @media ${DEVICE.tabletG} {
    grid-column: 2 / 7;
    grid-row: 2;
  }
`

export const PageTitle = styled(HeroH1)`
  font-style: normal;
  padding-top: 24px;

  @media ${DEVICE.tabletG} {
    padding-top: unset;
    padding-right: 24px;
    font-size: 3.6em;
  }

  @media ${DEVICE.laptopS} {
    font-size: 4.8rem;
  }

  @media ${DEVICE.laptopL} {
    font-size: 6rem;
  }
`

export const HeroText = styled(HeroP)`
  margin-top: 14px;
  padding-bottom: 24px;

  @media ${DEVICE.tabletG} {
    padding-right: 24px;
    margin-top: 16px;
  }

  @media ${DEVICE.desktop} {
    padding-bottom: unset;
  }
`

export const HeroGraphicContainer = styled.div`
  grid-column: 5 / 13;
  grid-row: 3 / 5;

  @media ${DEVICE.mobileL} {
    grid-row: 3;
    grid-column: 6 / 11;
  }

  @media ${DEVICE.tabletG} {
    grid-row: 1;
    grid-column: 8 / 13;
  }
`

export const HeroImage = styled.img`
  grid-column: 2 / 10;
  grid-row: 4 / 6;
  border-radius: 5px;

  @media ${DEVICE.mobileL} {
    grid-column: 4 / 9;
  }

  @media ${DEVICE.tabletG} {
    grid-column: 7 / 11;
    grid-row: 2 / 4;
  }
`
